import React from 'react';
import Image from 'next/image';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from 'styles/pages/home.module.scss';
import getBrands from 'lib/getBrands';
import getPosts from 'lib/getPosts';
import getSegmentsModels from 'lib/getSegmentsModels';
import getModels from 'lib/getModels';
import Link from 'components/link';
import { randIndex } from 'tools/functions';

const WidgetLaunches = dynamic(() => import('../components/widgetLaunches'), {
    ssr: false,
});

const WidgetPromo = dynamic(() => import('../components/widgetPromotion'), {
    ssr: false,
});

const WidgetSegments = dynamic(() => import('../components/widgetSegments'), {
    ssr: false,
});

const Home = (props) => {
    const { selectBrands, selectSegments, selectModels, recentModels, randPromos } =
        props;
    return (
        <div className={styles.root}>
            <Head>
                <title>
                    Voiture neuve &amp; occasion au Maroc | actualité automobile |
                    SoeezAuto{' '}
                </title>
                <meta
                    name="description"
                    content="Découvrez le portail voiture au Maroc, annonces voiture occasion, promotions, fiches techniques et comparateurs voiture neuve Maroc, actualité automobile. "
                />
                <meta property="fb:pages" content="643004532437221" />
                {/* <!-- Facebook Meta Tags --> */}
                <meta property="og:url" content={process.env.NEXT_PUBLIC_CLIENT_HOST} />
                <meta property="og:type" content="siteweb" />
                <meta
                    property="og:title"
                    content="soeezauto.ma - voitures neuves, promotions voitures, actualités"
                />
                <meta
                    property="og:description"
                    content="Découvrez le portail voiture au Maroc, annonces voiture occasion, promotions, fiches techniques et comparateurs voiture neuve Maroc, actualité automobile. "
                />
                <meta
                    property="og:image"
                    content={`${process.env.NEXT_PUBLIC_API_HOST}/images/og/home.png`}
                />

                <link rel="canonical" href={process.env.NEXT_PUBLIC_CLIENT_HOST} />
                {/* <!-- Twitter Meta Tags --> */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="soeezauto.ma - voitures neuves, promotions voitures, actualités"
                />
                <meta
                    name="twitter:description"
                    content="Découvrez le portail voiture au Maroc, annonces voiture occasion, promotions, fiches techniques et comparateurs voiture neuve Maroc, actualité automobile. "
                />
                <meta
                    name="twitter:image"
                    content={`${process.env.NEXT_PUBLIC_API_HOST}/images/og/home.png`}
                />
            </Head>

            <main>
                <div className="main-title">
                    <h1>Voiture neuve au Maroc</h1>
                </div>
                <div className={styles.mainContainer}>
                    <div className={styles.cardsRoot}>
                        <Card className={styles.cardRoot}>
                            <CardHeader title={<h2>Marques</h2>} />
                            <CardContent className={styles.cardContent}>
                                {selectBrands.map((brand) => (
                                    <Box key={brand.brand}>
                                        <Link
                                            href={`/marques-voiture/${brand.brand.replace(
                                                / /g,
                                                '-',
                                            )}`}
                                        >
                                            <Image
                                                src={`${process.env.NEXT_PUBLIC_API_HOST}/images/brands/${brand.image}`}
                                                alt={brand.brand}
                                                width="60"
                                                height="60"
                                                loading="eager"
                                                priority
                                            />
                                        </Link>
                                    </Box>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Link href="/marques-voiture">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        Toutes les marques
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                    </div>
                    <div className={styles.cardsRoot}>
                        <Card className={styles.cardRoot}>
                            <CardHeader title={<h2>Modèles</h2>} />
                            <CardContent className={styles.cardContent}>
                                {selectModels.map((model) => (
                                    <Box key={model.model}>
                                        <Link
                                            href={`/modeles-voiture/${model.brand.brand.replace(
                                                / /g,
                                                '-',
                                            )}/${model.model.replace(/ /g, '-')}`}
                                        >
                                            <Image
                                                src={`${process.env.NEXT_PUBLIC_API_HOST}/images/models/${model.images[0].filename}`}
                                                alt={model.model}
                                                width="90"
                                                height="60"
                                                loading="eager"
                                                priority
                                            />
                                        </Link>
                                        <span
                                            className={styles.subtitle}
                                        >{`${model.brand.brand} ${model.model}`}</span>
                                    </Box>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Link href="/modeles-voiture">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        Tous les modèles
                                    </Button>
                                </Link>
                            </CardActions>
                        </Card>
                    </div>
                    <WidgetPromo data={randPromos} />
                    <WidgetLaunches data={recentModels} />
                    <WidgetSegments selectSegments={selectSegments} />
                </div>
            </main>
        </div>
    );
};

Home.propTypes = {
    selectBrands: PropTypes.array.isRequired,
    selectSegments: PropTypes.array.isRequired,
    selectModels: PropTypes.array.isRequired,
    recentModels: PropTypes.array.isRequired,
    randPromos: PropTypes.array.isRequired,
};

export default Home;

export async function getStaticProps() {
    let brands = await getBrands();
    let posts = await getPosts();
    let segments = await getSegmentsModels();
    let models = await getModels();
    brands = brands.data.brands;
    segments = segments.data.segments;
    posts = posts.data.posts;
    models = models.data.models;
    const randMod = randIndex(19, 6);
    const recentModels = models.filter((model, ind) => {
        return randMod.includes(ind);
    });
    // rand promos
    const promos = [];
    models.forEach((model) => {
        const promoVersions = model.versions.filter((version) => {
            return version.prices.edges[0].node.promo !== null;
        });
        if (promoVersions.length > 0) {
            // eslint-disable-next-line no-param-reassign
            model.versions = promoVersions;
            promos.push(model);
        }
    });

    const selected = {
        brands: randIndex(brands.length, 6),
        segments: randIndex(segments.length, 6),
        models: randIndex(models.length, 6),
        promos: randIndex(promos.length, 6),
    };
    const selectBrands = brands.filter((brand, ind) => {
        return selected.brands.includes(ind + 1);
    });
    const selectSegments = segments.filter((segment, ind) => {
        return selected.segments.includes(ind + 1);
    });
    selectSegments.forEach((seg) => {
        // eslint-disable-next-line no-param-reassign
        seg.image = seg.models[randIndex(seg.models.length - 1, 1)].images[0].filename;
    });
    const selectModels = models.filter((model, ind) => {
        return selected.models.includes(ind + 1);
    });
    const randPromos = promos.filter((model, ind) => {
        return selected.promos.includes(ind + 1);
    });

    const postsWithImage = posts.edges.filter((post) => {
        return post.node.featuredImage;
    });
    return {
        props: {
            selectBrands,
            postsWithImage,
            selectSegments,
            selectModels,
            randMod,
            recentModels,
            randPromos,
        },
    };
}
